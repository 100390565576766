@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&amp;display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply px-4 py-2 rounded-md flex gap-1 items-center justify-center w-full;
}

.btn-primary {
  @apply bg-primary1 text-white;
}

.btn-primary:hover {
  @apply bg-primary2;
}

.btn-secondary {
  @apply bg-gray-500 text-white;
}

.btn-secondary:hover {
  @apply bg-gray-600;
}

.btn-danger {
  @apply bg-red-500 text-white;
}

.btn-danger:hover {
  @apply bg-red-600;
}

.btn-success {
  @apply bg-green-600 text-white;
}

.btn-success:hover {
  @apply bg-green-700;
}

.btn-warning {
  @apply bg-yellow-600 text-white;
}

.btn-warning:hover {
  @apply bg-yellow-700;
}

.form-input {
  @apply mt-1 block w-full rounded-md;
  @apply border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
  @apply max-h-fit;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  /* :hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  } */

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track:hover {
    box-shadow: inset 0 0 5px grey;
    border-radius: 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ff0008;
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #e71837;
  }
}

.text-shadow-white {
  text-shadow: 0px 1px 7px rgba(255, 255, 255, 1);
}
